<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <div
          class="card-title d-flex justify-content-between align-items-center mb-3"
      >
        <h3 class="fw-medium fs-18 mb-0">All Orders</h3>
      </div>

      <div class="all-table scroll-bar h-660">
        <table class="table align-middle mb-0">
          <thead>
          <tr>
            <th class="text-dark fw-medium pt-2 pb-2 fs-14 ps-0" scope="col">Project</th>
            <th class="text-dark fw-medium pt-2 pb-2 fs-14" scope="col">Deadline</th>
            <th class="text-dark fw-medium pt-2 pb-2 fs-14" scope="col">Article</th>
            <th class="text-dark fw-medium pt-2 pb-2 fs-14" scope="col">Word</th>
            <th class="text-dark fw-medium pt-2 pb-2 fs-14 text-center" scope="col">Actions</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="data in dataOrders" :key="data.id">
            <td class="ps-0">
              <router-link
                  :to="'/performer-edit/' + data.id"
                  class="text-decoration-none"
                  @click="viewOrder(data)"
              >
                <h5 class="fs-14 text-dark mb-0">{{ data.project.name }}</h5>
              </router-link>
            </td>
            <td>
              <span class="text-body">{{ data.dead_line }}</span>
            </td>
            <td>
              <span class="text-body">{{ data.article_type?.name }}</span>
            </td>
            <!--            <td>-->
            <!--              <span class="text-body d-block mb-1">{{ data.user }}</span>-->
            <!--            </td>-->
            <td>
              <span class="rounded-0 text-primary">{{ data.word_count }}</span>
            </td>
            <td class="text-end text-center">
              <div class="dropdown action-opt">
                <button
                    class="btn bg-transparent p-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                  <vue-feather type="more-horizontal"></vue-feather>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                        :to="'/performer-edit/' + data.id"
                        class="dropdown-item"
                        @click="viewOrder(data)"

                    >
                      <vue-feather type="eye"></vue-feather>
                      View
                    </router-link>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:;">
                      <vue-feather type="trash"></vue-feather>
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'PerformerTable',
  computed: {
    ...mapGetters(['dataOrders'])
  },
  methods: {
    viewOrder(request) {
      this.$store.commit('SET_CURRENT_ORDER', request);
      console.log('Order details:', request);
    },
  }
};
</script>