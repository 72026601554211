import axios from 'axios';
import {Module} from 'vuex';
import {BACKEND_URL} from '@/constants';

interface AuthState {
    token: string | null;
    loginError: string | null;
    isLoggedIn: boolean;
    user: {
        username: string | null;
        firstName: string | null;
        status: string | null;
        is_writer: boolean;
    };
}

const authModule: Module<AuthState, any> = {
    state: {
        token: localStorage.getItem('token'),
        loginError: null,
        isLoggedIn: false,
        user: {
            username: null,
            firstName: null,
            status: null,
            is_writer: false,
        },
    },

    getters: {
        isLoggedIn: (state: AuthState) => state.token !== null,
        username: (state: AuthState) => state.user.username,
        firstName: (state: AuthState) => state.user.firstName,
        status: (state: AuthState) => state.user.status,
        is_writer: (state) => state.user.is_writer,
        token: (state) => state.token,
        getLoginError: (state) => state.loginError,
    },

    mutations: {
        SET_TOKEN(state: AuthState, token: string | null) {
            state.token = token;
        },

        SET_LOGIN_ERROR(state: AuthState, error: string | null) {
            state.loginError = error;
        },

        SET_USER(state: AuthState, userData: {
            username: string | null;
            firstName: string | null;
            status: string | null;
            is_writer: boolean
        }) {
            state.user = userData;
        },
    },

    actions: {
        async login({commit, dispatch}, credentials: { username: string; password: string }) {
            try {
                const response = await axios.post(`${BACKEND_URL}/api/users/login/`, credentials);
                const {access: token} = response.data;

                commit('SET_TOKEN', token);
                localStorage.setItem('token', token);
                commit('SET_LOGIN_ERROR', null);

                commit('TOGGLE_COLLAPSED', false);
                await dispatch('fetchOrGetUserData');

                await dispatch('fetchOrdersData', token, {root: true});
            } catch (error) {
                let errorMessage = 'An error occurred';
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        errorMessage = error.response.data?.detail || 'An error occurred';
                    }
                } else {
                    errorMessage = 'Unexpected error occurred';
                }

                console.error('Login error:', errorMessage);

                commit('SET_LOGIN_ERROR', errorMessage);
                localStorage.removeItem('token');
            }
        },

        async googleLogin({commit, dispatch}, code: string) {
            try {
                const response = await axios.post(`${BACKEND_URL}/api/users/token/google/`, {code});
                const {access: token} = response.data;

                if (response.data.message) {
                    commit('SET_LOGIN_ERROR', response.data.message);
                    // alert(response.data.message)
                    // window.location.reload();
                }

                commit('SET_TOKEN', token);
                localStorage.setItem('token', token);

                commit('SET_LOGIN_ERROR', null);
                await dispatch('fetchOrGetUserData');
            } catch (error) {
                let errorMessage = 'An error occurred during Google login';
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        errorMessage = error.response.data?.detail || 'Your account is pending for review';
                    }
                } else {
                    errorMessage = 'Unexpected error occurred';
                }

                console.error('Google login error:', errorMessage);

                commit('SET_LOGIN_ERROR', errorMessage);
                localStorage.removeItem('token');
            }
        },

        async verifyToken(_, token: string) {
            try {
                const response = await axios.post(`${BACKEND_URL}/api/users/token/verify/`, {token});
                return response.status === 200;
            } catch (error) {
                console.error('Token verification error:', error);
                return false;
            }
        },

        logout({commit}) {
            commit('TOGGLE_COLLAPSED', true);
            commit('SET_TOKEN', null);
            localStorage.removeItem('token');
            commit('SET_USER', {username: null, status: null});
            localStorage.removeItem('user');
        },

        async fetchOrGetUserData({commit}) {
            const userString = localStorage.getItem('user');
            let userData = {username: null, firstName: null, status: null, is_writer: false};

            if (userString) {
                userData = JSON.parse(userString);
                commit('SET_USER', userData);
            } else {
                try {
                    const token = localStorage.getItem('token');
                    if (token) {
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    }

                    const response = await axios.get(`${BACKEND_URL}/api/user/details/`);
                    if (response.status === 200) {
                        userData = {
                            username: response.data.username,
                            firstName: response.data.first_name, // Изменено на firstName
                            status: response.data.status,
                            is_writer: response.data.is_writer,
                        };
                        commit('SET_USER', userData);
                        localStorage.setItem('user', JSON.stringify(userData));
                    } else {
                        throw new Error('Invalid response status');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    commit('SET_TOKEN', null);
                    commit('SET_USER', {username: null, firstName: null, status: null, is_writer: false});
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                }
            }
        }
    },
};

export default authModule;
