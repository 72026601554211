
import {defineComponent} from "vue";

import VerticalForm from "../../components/Form/FormLayout/VerticalForm.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "NewContentPage",
  components: {
    VerticalForm,
    MainFooter,
  },
});
