
import {defineComponent} from "vue";
import {mapGetters, mapActions} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default defineComponent({
  name: "PerformerEdit",
  computed: {
    ...mapGetters(['currentOrder']),
    isEditing(): boolean {
      return this.editing;
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // Дополнительные настройки редактора
      },
      editing: false,
    }
  },
  methods: {
    ...mapActions(['updateOrder', 'fetchCurrentOrder', 'fetchOrdersData']),
    toggleEdit() {
      this.editorData = this.currentOrder.content; // Инициализация editorData содержимым currentOrder.content
      this.editing = !this.editing;
    },
    async saveChanges() {
      try {
        const orderData = {
          content: this.editorData
        };
        await this.updateOrder({id: this.currentOrder.id, orderData});
        await this.fetchOrdersData();
        this.toggleEdit();
        await this.fetchCurrentOrder(this.currentOrder.id); // Обновляем содержимое заказа после сохранения изменений
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    async check() {
      try {
        const orderData = {
          content: this.editorData,
          status: 3,
        };
        await this.updateOrder({id: this.currentOrder.id, orderData});
        await this.fetchOrdersData();
        this.toggleEdit();
        await this.fetchCurrentOrder(this.currentOrder.id); // Обновляем содержимое заказа после сохранения изменений
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    cancelEdit() {
      this.toggleEdit();
    }
  },
  created() {
    if (!this.currentOrder) {
      // const slug = window.location.pathname.split('/').pop();
      const slug = this.$route.params.id
      this.fetchCurrentOrder(slug);
    }
  }
});
