<template>
  <div>
    <div class="card active-projects-content border-0 box-shadow border-radius-10 mb-4">
      <div class="card-body p-4">
        <h3 class="text-center">Step 1: Basic Information</h3>
        <div class="step-wrapper">
          <ul class="step-content ps-0 mb-4 list-unstyled d-flex justify-content-center">
            <li class="text-center">
              <span class="fw-medium text-white fs-14 active">1</span>
              <p class="fw-medium text-dark fs-14 mt-2">Basic Information</p>
            </li>
            <li class="text-center">
              <span class="fw-medium text-dark fs-14">2</span>
              <p class="fw-medium text-dark fs-14 mt-2">Article Settings</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card border-0 box-shadow border-radius-10 mb-4">
      <form class="card-body p-4 was-validated">
        <div class="form-group mb-4">
          <label class="label">Project</label>
          <select class="form-select form-control"
                  :value="formData.project"
                  @change="onInput($event.target.value, 'project')"
                  required
          >
            <option disabled value="">Select Project</option>
            <option
                v-for="project in userProjectsData"
                :key="project.id"
                :value="project.id">
              {{ project.name }}
            </option>
          </select>
        </div>

        <div class="form-group mb-4">
          <label class="label">Article Type</label>
          <select
              class="form-select form-control"
              :value="formData.article_type"
              @change="onInput($event.target.value, 'article_type')"
              required
          >
            <option disabled value="">Select Article Type</option>
            <option
                v-for="article in projectData.article_type"
                :key="article.id"
                :value="article.id">
              {{ article.name }}
            </option>
          </select>
        </div>

<!--        <div v-if="subtypes.length" class="form-group mb-4">-->
<!--          <label class="label">Article Subtype</label>-->
<!--          <select-->
<!--              class="form-select form-control"-->
<!--              :value="formData.article_sub_type"-->
<!--              @change="onInput($event.target.value, 'article_sub_type')"-->
<!--              required-->
<!--          >-->
<!--            <option disabled value="">Select Article Subtype</option>-->
<!--            <option v-for="subtype in subtypes" :key="subtype.id" :value="subtype.id">-->
<!--              {{ subtype.name }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->


        <div v-if="subtypes.length" class="form-group mb-4">
          <label class="label">Article Subtype</label>
          <select
              class="form-select form-control"
              :value="formData.article_sub_type"
              @change="onInput($event.target.value, 'article_sub_type')"
              required
          >
            <option disabled value="">Select Article Subtype</option>
            <option v-for="subtype in subtypes" :key="subtype.id" :value="subtype.id">
              {{ subtype.name }}
            </option>
          </select>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'StepOne',
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['projectData', 'userProjectsData']),
    subtypes() {
      const selectedType = this.projectData.article_type.find(type => type.id === +this.formData.article_type);
      return selectedType?.sub_type || [];
    },
  },
  methods: {
    ...mapActions(['fetchProjectData', 'fetchUserProjectData']),
    onInput(value, key) {
      this.$emit('updateFormData', {[key]: value});
    },
  },
  created() {
    this.fetchProjectData();
    this.fetchUserProjectData();
  },
};
</script>
