import axios from "axios";
import {BACKEND_URL} from "@/constants";
import {ActionContext} from "vuex";

interface Request {
    uuid: string;
    result?: { title: string };
    user?: { username: string };
    created_at: string;
    status: string;
}

interface State {
    requests: Request[];
    totalPages: number;
    currentPage: number;
}

const contentManagementModule = {
    state: {
        requests: [] as Request[],
        totalPages: 0,
        currentPage: 1,
    },
    getters: {
        allRequests: (state: State) => state.requests,
        totalPages: (state: State) => state.totalPages,
        currentPage: (state: State) => state.currentPage,
    },
    mutations: {
        SET_REQUESTS: (
            state: State,
            {requests, totalPages, currentPage}: { requests: Request[]; totalPages: number; currentPage: number }
        ) => {
            state.requests = requests;
            state.totalPages = totalPages;
            state.currentPage = currentPage;
        },
    },
    actions: {
        async fetchRequests({commit}: ActionContext<State, any>, {
            page = 1,
            searchQuery = "",
            sortDate = "",
            status = ""
        } = {}) {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                }

                const url = `${BACKEND_URL}/api/results/`;
                const response = await axios.get(url, {
                    params: {
                        page,
                        search: searchQuery,
                        status: status,
                        ordering:sortDate,
                    },
                });
                commit("SET_REQUESTS", {
                    requests: response.data.results,
                    totalPages: response.data.total_pages,
                    currentPage: response.data.current_page,
                });
            } catch (error) {
                console.error("Error fetching requests:", error);
            }
        },
        async deleteRequest({dispatch, state}: ActionContext<State, any>, uuid: string) {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                }

                const url = `${BACKEND_URL}/api/result/${uuid}/`;
                await axios.delete(url);
                dispatch("fetchRequests", {page: state.currentPage});
            } catch (error) {
                console.error("Error deleting request:", error);
            }
        },
    },
};

export default contentManagementModule;
