
import {defineComponent} from "vue";
import MainFooter from "@/components/Layouts/MainFooter.vue";
import AllOrders from "@/components/ProjectManagement/ProjectList/AllOrders.vue";


export default defineComponent({
  name: "AllOrdersPage",
  components: {
    AllOrders,
    MainFooter
  },
});
