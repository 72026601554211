
  import { defineComponent } from "vue";
  import OutlinePostOverview from "@/components/ProjectManagement/Overview/OutlinePostOverview.vue";
  import axios from 'axios';

  import { BACKEND_URL } from "@/constants";

  export default defineComponent({
    name: "OutlinePostPage",
    components: {
        OutlinePostOverview,
    },
    data() {
    return {
      data: [],
      exist: true,
    };
  },
  created() {
    const token = localStorage.getItem('token');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    const slug = window.location.pathname.split('/').pop()

    const url = BACKEND_URL

    axios.get(`${url}/outline/outline-posts/${slug}`)
      .then(response => {
        this.data = response.data;
        if (response.data.status !== 'done') {
            this.exist = false
        }
        console.log(response.data)
      })
      .catch(error => {
        console.error('Error fetching requests:', error);
        this.exist = false
      });
  },
  });
  