
import {defineComponent, PropType, ref} from "vue";
import axios from "axios";
import {BACKEND_URL} from "@/constants";


export default defineComponent({
  name: "OutlinesTable",
  props: {
    data: {
      type: Array as PropType<Array<{
        id: number,
        uuid: string,
        status: string,
        query: string,
        url: string,
        content: string,
        user: {
          "username": string,
        },
      }>>,
      required: true,
    },
  },
  methods: {
    computeClass(classValue: string) {
      return {
        "text-primary": this.wordActive === classValue,
        "text-danger": this.wordPending === classValue,
        "text-success": this.wordCompleted === classValue,
      };
    },
    async deleteRequest(id: number){
      try {
        const token = window.localStorage.getItem("token");
        await axios.delete(`${BACKEND_URL}/outline/outline-posts/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        this.$emit("update:data", this.data.filter(post => post.id !== id));
        // сделать обновления дати
      } catch (error) {
        console.error(error);
      }
    }
  },
  setup() {
    const wordActive = ref("In Progress");
    const wordPending = ref("Failed");
    const wordCompleted = ref("Done");

    return {
      wordActive,
      wordPending,
      wordCompleted,
    };
  },

});
