
import { computed } from "vue";
import store from "@/store/store";

export default {
  name: "MainSidebar",
  setup() {
    const logout = () => {
      store.dispatch("logout");
    };

    const userStatus = computed(() => store.getters['status']);
    const isWriter = computed(() => store.getters['is_writer']);

    return {
      logout,
      userStatus,
      isWriter,
    };
  },
};
