import { Module } from 'vuex';

interface LayoutState {
    isCollapsed: boolean;
}

const layoutModule: Module<LayoutState, any> = {
    state: {
        isCollapsed: true,
    },
    mutations: {
        TOGGLE_COLLAPSED(state: LayoutState) {
            state.isCollapsed = !state.isCollapsed;
        },
    },
    getters: {
        isCollapsed: (state: LayoutState) => state.isCollapsed,
    },
};

export default layoutModule;
