<template>
  <div class="container-fluid">
    <div class="inner-main-content">

      <div v-if="currentOrder" class="card active-projects-content border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="card-title d-flex justify-content-between align-items-center mb-3">
            <h3 class="fw-medium fs-18 mb-0">Order Overview</h3>
            <div class="dropdown action-opt">
              <button
                  class="btn bg-transparent p-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :disabled="!currentOrder || currentOrder.status.name !== 'New'"
              >
                <vue-feather type="more-horizontal"></vue-feather>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a class="dropdown-item" href="javascript:;" @click="toggleEditMode">
                    <vue-feather type="edit"></vue-feather>
                    Edit
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Project</label>
                  <select class="form-select form-control"
                          v-model="currentOrder.project.id"
                          :disabled="!editMode">
                    <option disabled value="">Select Project</option>
                    <option
                        v-for="project in userProjectsData"
                        :key="project.id"
                        :value="project.id"
                    >
                      {{ project.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Select Language</label>
                  <select class="form-select form-control"
                          v-model="currentOrder.selected_language"
                          :disabled="!editMode">
                    <option disabled value="">Select language</option>
                    <option
                        v-for="lang in projectData.languages"
                        :key="lang.id"
                        :value="lang"
                    >
                      {{ lang.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Article Type</label>
                  <select class="form-select form-control"
                          v-model="currentOrder.article_type.id"
                          :disabled="!editMode">
                    <option disabled value="">Select Article</option>
                    <option
                        v-for="article in projectData.article_type"
                        :key="article.id"
                        :value="article.id"
                    >
                      {{ article.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Select Country</label>
                  <select class="form-select form-control" v-model="currentOrder.selected_country"
                          :disabled="!editMode">
                    <option disabled value="">Select country</option>
                    <option
                        v-for="country in projectData.countries"
                        :key="country.id"
                        :value="country"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Article Subtype</label>
                  <select
                      class="form-select form-control"
                      v-model="articleSubTypeId"
                      :disabled="!editMode"
                  >
                    <option disabled value="">Select Article Subtype</option>
                    <option v-for="subtype in subtypes" :key="subtype.id" :value="subtype.id">
                      {{ subtype.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Word count</label>
                  <input v-model.number="currentOrder.word_count" type="number" min="0" class="form-control"
                         placeholder="Word count" :disabled="!editMode"/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Main key</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Title"
                      v-model="currentOrder.main_keyword"
                      :disabled="!editMode"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Images count</label>
                  <input v-model.number="currentOrder.images_count" type="number" min="0" class="form-control"
                         placeholder="Word count" :disabled="!editMode"/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Additional keywords</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Additional keywords, not required, up to 10, list of strings"
                      v-model="currentOrder.additional_keyword"
                      :disabled="!editMode"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Additional Requirements</label>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Additional Requirements, not required, up to 10, list of strings"
                      v-model="currentOrder.additional_requirements"
                      :disabled="!editMode"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group mb-4">
                  <label class="label">Deadline</label>
                  <input v-model="currentOrder.dead_line" type="date" class="form-control" :disabled="!editMode"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-check c-form-check">
                  <input class="form-check-input" type="checkbox" id="MetaDescription"
                         v-model="currentOrder.meta_description" :disabled="!editMode"/>
                  <label class="form-check-label text-body fs-14" for="MetaDescription">Meta Description</label>
                </div>
                <div class="form-check c-form-check">
                  <input class="form-check-input" type="checkbox" id="flexCheckDefault1" v-model="currentOrder.brand"
                         :disabled="!editMode"/>
                  <label class="form-check-label text-body fs-14" for="flexCheckDefault1">Brand</label>
                  <div class="col-lg-12" v-if="currentOrder.brand">
                    <div class="form-group mb-4">
                      <label class="label">Brand Name</label>
                      <input type="text" class="form-control" placeholder="Enter brand name"
                             v-model="currentOrder.brand_name" :disabled="!editMode"/>
                    </div>
                    <div class="form-group mb-4">
                      <label class="label">Brand Description</label>
                      <input type="text" class="form-control" placeholder="Enter brand description"
                             v-model="currentOrder.brand_description" :disabled="!editMode"/>
                    </div>
                    <div class="form-check c-form-check">
                      <input class="form-check-input" type="checkbox" id="flexCheckDefault2"
                             v-model="currentOrder.include" :disabled="!editMode"/>
                      <label class="form-check-label text-body fs-14" for="flexCheckDefault2">Include</label>
                      <div class="col-lg-12" v-if="currentOrder.include">
                        <div class="form-check c-form-check">
                          <input class="form-check-input" type="checkbox" id="ToTitleCheckbox"
                                 v-model="currentOrder.to_title" :disabled="!editMode"/>
                          <label class="form-check-label text-body fs-14" for="ToTitleCheckbox">To Title</label>
                        </div>
                        <div class="form-check c-form-check">
                          <input class="form-check-input" type="checkbox" id="DescriptionCheckbox"
                                 v-model="currentOrder.to_description" :disabled="!editMode"/>
                          <label class="form-check-label text-body fs-14" for="DescriptionCheckbox">To
                            Description</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                <p class="label">Content</p>
                <div v-html="currentOrder.content"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-6">
                <button v-if="editMode" @click.prevent="saveChanges" class="btn btn-primary">Save</button>
                <button v-if="editMode" @click.prevent="cancelEdit" class="btn btn-secondary ms-2">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-else>Loading...</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'OrderOverview',

  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    ...mapGetters(['currentOrder', 'projectData', 'userProjectsData']),
    subtypes() {
      const selectedType = this.projectData.article_type.find(type => type.id === this.currentOrder.article_type.id);
      return selectedType?.sub_type || [];
    },
    articleSubTypeId: {
      get() {
        return this.currentOrder.article_sub_type ? this.currentOrder.article_sub_type.id : null;
      },
      set(value) {
        if (this.currentOrder.article_sub_type) {
          this.currentOrder.article_sub_type.id = value;
        } else {
          this.currentOrder.article_sub_type = {id: value};
        }
      }
    }
  },
  methods: {
    ...mapActions(['fetchCurrentOrder', 'updateOrder', 'fetchOrdersData', 'fetchUserProjectData']),
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    saveChanges() {
      const orderData = {
        ...this.currentOrder,
        project: this.currentOrder.project.id,
        status: this.currentOrder.status.id,
        selected_country: this.currentOrder.selected_country.id,
        selected_language: this.currentOrder.selected_language.id,
        article_type: this.currentOrder.article_type.id,
        article_sub_type: this.currentOrder.article_sub_type.id,
      };
      this.$store.dispatch('updateOrder', {id: this.currentOrder.id, orderData})
          .then(() => {
            this.fetchOrdersData();
            this.editMode = false;
          })
          .catch(error => {
            console.error('Error updating order:', error);
          });
    },
    cancelEdit() {
      this.editMode = false;
      const slug = this.$route.params.id;
      this.fetchCurrentOrder(slug);
    },
  },
  created() {
    this.fetchUserProjectData();
    if (!this.currentOrder) {
      const slug = this.$route.params.id;
      this.fetchCurrentOrder(slug);
    }
  }
};
</script>
