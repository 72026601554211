
import {defineComponent} from "vue";
import PreLoader from "./components/Layouts/PreLoader.vue";
import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import SettingsOption from "./components/Layouts/SettingsOption.vue";
import SettingsModal from "./components/Layouts/SettingsModal.vue";
import {mapActions, mapGetters} from "vuex";
import store from "@/store/store";

export default defineComponent({
  name: "App",
  components: {
    PreLoader,
    MainHeader,
    MainSidebar,
    SettingsOption,
    SettingsModal,
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isCollapsed"]),
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);

    const userString = localStorage.getItem("user");
    let userData = {username: null, status: null};

    if (userString) {
      userData = JSON.parse(userString);
    }

    store.commit("SET_USER", userData);

    const token = localStorage.getItem("token");


    try {
      if (token) {
        await this.fetchOrdersData(token);
        await this.fetchAllUsers(token);
        await this.fetchProjectData();
        await this.fetchProductivityData();
        await this.fetchOrGetUserData();
      } else {
        console.error("Отсутствует токен");
      }
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    }
  },
  methods: {
    ...mapActions(["fetchOrdersData", "fetchAllUsers", "fetchProjectData", "fetchProductivityData","fetchOrGetUserData"]),
  },
});
