<template>
  <div
      class="card active-projects-content border-0 box-shadow border-radius-10 mb-4"
  >
    <div class="card-body p-6">
      <div
          class="card-title d-flex justify-content-between align-items-center mb-3"
      >
        <h3 class="fw-medium fs-18 mb-0">Post Overview</h3>

        <ul
            class="nav nav-tabs user-tabs border-0 mb-4"
            id="myTab"
            role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
                class="nav-link border-0 text-body fs-14 active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
            >
              Styled
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
                class="nav-link border-0 text-body fs-14"
                id="teams-tab"
                data-bs-toggle="tab"
                data-bs-target="#teams-tab-pane"
                type="button"
                role="tab"
                aria-controls="teams-tab-pane"
                aria-selected="false"
            >
              Raw
            </button>
          </li>
        </ul>
      </div>
      <div class="row">
        <div>
          <ul>
            <li class="fs-14 fw-medium text-body mb-3">
              <vue-feather
                  type="book"
                  style="width: 18px; stroke: #919aa3"
              ></vue-feather>
              <span class="text-dark ms-2">Title:</span> {{ data.result?.title }}
            </li>
            <li class="fs-14 fw-medium text-body mb-3">
              <vue-feather
                  type="book-open"
                  style="width: 18px; stroke: #919aa3"
              ></vue-feather>
              <span class="text-dark ms-2">Description:</span> {{ data.result?.description }}
            </li>
            <li class="fs-14 fw-medium text-body mb-3">
              <vue-feather
                  type="align-left"
                  style="width: 18px; stroke: #919aa3"
              ></vue-feather>
              <span class="text-dark ms-2">Content:</span> <br>
              <div>
                <!--                                            {{data.result?.content}}-->
                <div>
                  <div class="tab-content" id="myTabContent">
                    <div
                        class="tab-pane fade show active"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabindex="0"
                    >
                      <div v-html="data.result?.content"></div>

                    </div>
                    <div
                        class="tab-pane fade"
                        id="teams-tab-pane"
                        role="tabpanel"
                        aria-labelledby="teams-tab"
                        tabindex="0"
                    >
                      {{ data.result?.content }}
                    </div>
                  </div>
                </div>


              </div>
            </li>
            <li class="fs-14 fw-medium text-body mb-3">
              <vue-feather
                  type="calendar"
                  style="width: 18px; stroke: #919aa3"
              ></vue-feather>
              <span class="text-dark ms-2">Started:</span> {{ formatDate(data.created_at) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectOverview",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    }
  }
};
</script>