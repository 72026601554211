
import {defineComponent, ref, computed, onMounted} from "vue";
import {useStore} from "vuex";

interface RootState {
  allRequests: any[];
  totalPages: number;
  currentPage: number;
}

export default defineComponent({
  name: "ProjectTable",
  setup() {
    const store = useStore<RootState>();

    const wordActive = ref("In Progress");
    const wordPending = ref("Failed");
    const wordCompleted = ref("Done");

    const allRequests = computed(() => store.getters.allRequests);
    const totalPages = computed(() => store.getters.totalPages);
    const currentPage = computed(() => store.getters.currentPage);

    const searchQuery = ref("");
    const sortDate = ref("-created_at");
    const status = ref("done");

    const displayedPages = computed(() => {
      const pages = [];
      const maxPages = 5;
      const half = Math.floor(maxPages / 2);
      let start = currentPage.value - half;
      let end = currentPage.value + half;

      if (start < 1) {
        start = 1;
        end = maxPages;
      }

      if (end > totalPages.value) {
        end = totalPages.value;
        start = totalPages.value - maxPages + 1;
      }

      if (start < 1) {
        start = 1;
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    });

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };

    const computeClass = (classValue: string) => {
      return {
        "text-primary": wordActive.value === classValue,
        "text-danger": wordPending.value === classValue,
        "text-success": wordCompleted.value === classValue,
      };
    };

    const goToPage = (page: number) => {
      if (page > 0 && page <= totalPages.value) {
        store.dispatch("fetchRequests", {
          page,
          searchQuery: searchQuery.value,
          sortDate: sortDate.value,
          status: status.value,
        });
      }
    };

    const searchRequests = () => {
      store.dispatch("fetchRequests", {
        searchQuery: searchQuery.value,
        sortDate: sortDate.value,
        status: status.value,
      });
    };

    const deleteRequest = (uuid: string) => {
      store.dispatch("deleteRequest", uuid)
    }

    const checkAndFetchRequests = () => {
      if (window.location.pathname === "/") {
        store.dispatch("fetchRequests", {
          page: currentPage.value,
          searchQuery: searchQuery.value,
          sortDate: sortDate.value,
          status: status.value,
        });
      }
    };

    onMounted(() => {
      checkAndFetchRequests();
      setInterval(checkAndFetchRequests, 30000);
    });

    return {
      wordActive,
      wordPending,
      wordCompleted,
      allRequests,
      totalPages,
      currentPage,
      displayedPages,
      searchQuery,
      formatDate,
      computeClass,
      goToPage,
      searchRequests,
      deleteRequest,
      sortDate,
      status,
    };
  },
});
