
import {defineComponent} from "vue";

import NewOutlinePostForm from "@/components/Form/FormLayout/NewOutlinePostForm.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "NewOutlinePost",
  components: {
    NewOutlinePostForm,
    MainFooter,
  },
});
