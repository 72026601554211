
import {defineComponent} from "vue";
import MainFooter from "@/components/Layouts/MainFooter.vue";
import ContentOrderForm from "@/components/Form/FormLayout/ContentOrderForm.vue";


export default defineComponent({
  name: "ContentOrderPage",
  components: {
    ContentOrderForm,
    MainFooter
  },
});
