
import {defineComponent} from "vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import MainDashboard from "@/components/Dashboard/Main/MainDashboard.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    MainDashboard,

    MainFooter,
  },
});
