
import {computed, reactive} from "vue";
import {useStore} from "vuex";  // Доступ к Vuex
import axios from "axios";
import {useRoute} from "vue-router";
import {BACKEND_URL} from "@/constants";

export default {
  name: "MyProjectsEdit",
  setup() {
    const store = useStore();
    const route = useRoute();

    const project = computed(() => store.getters.currentOrder);

    // Используем reactive для отслеживания изменений данных формы
    const formData = reactive({
      name: project.value.name || "",
      domain: project.value.domain || "",
      info: project.value.info || ""
    });

    const submitForm = async () => {
      try {
        const response = await axios.put(`${BACKEND_URL}/api/orders/user-projects-list/${project.value.id}/`, {
          name: formData.name,
          domain: formData.domain,
          info: formData.info
        });
        console.log("Response:", response.data);
        // Дополнительные действия при успехе
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    return {
      formData,
      submitForm,
    };
  }
};
