


import MainFooter from "@/components/Layouts/MainFooter.vue";
import {defineComponent} from "vue";
import Performer from "@/components/Performer/Performer.vue";

export default defineComponent({
  name: "PerformerPage",
  components: {Performer, MainFooter},

});
