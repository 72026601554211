<template>
  <div v-if="currentOrder">
    <div class="card active-projects-content border-0 box-shadow border-radius-10 mb-4">
      <div class="card-body p-4">
        <div class="card-title d-flex justify-content-between align-items-center mb-2">
          <h3 class="fw-medium fs-18 mb-0">Active Project</h3>
        </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active mt-4" id="project-1-tab-pane" role="tabpanel"
               aria-labelledby="project-1-tab" tabindex="0">
            <div class="mb-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="d-flex align-items-center">
                    <div>
                      <img src="../../../assets/images/project-logo.png" alt="project-logo"/>
                    </div>
                    <div class="ms-3">
                      <h5 class="fs-14 fw-medium">{{ currentOrder.project.name }}</h5>
                      <p class="text-body">
                        <span class="text-dark">Client : {{
                            currentOrder.user.first_name || currentOrder.user.first_name
                          }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <span class="text-body d-block mb-1">Assigned To</span>
                      <a href="#" class="d-block text-decoration-none">
                        <span v-if="currentOrder.assigned_to"
                              class="text-dark ms-1">
                            {{ currentOrder.assigned_to.user.first_name || currentOrder.assigned_to.user.username }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step-wrapper">
              <ul class="step-content ps-0 mb-4 list-unstyled d-flex">
                <li class="text-center" v-for="(status, index) in projectData.status" :key="status.id">
                  <span class="fw-medium fs-14"
                        :class="{ 'text-white active': currentOrder.status.name === status.name }">{{
                      index + 1
                    }}</span>
                  <p class="fw-medium text-dark fs-14 mt-2">{{ status.name }}</p>
                </li>
              </ul>
              <div class="mw-785 m-auto">
                <ul class="d-flex justify-content-between align-items-center ps-0 mb-1 list-unstyled">
                  <li class="text-body fs-14 fw-medium">0%</li>
                  <li class="text-body fs-14 fw-medium">50%</li>
                  <li class="text-body fs-14 fw-medium">100%</li>
                </ul>
                <div class="progress rounde rounded-0 overflow-visible mb-4" role="progressbar"
                     aria-label="Example with label" :aria-valuenow="progressPercentage" aria-valuemin="0"
                     aria-valuemax="100" style="height: 8px">
                  <div class="progress-bar rounde rounded-0 overflow-visible"
                       :style="{ width: progressPercentage + '%', height: '8px' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card active-projects-content border-0 box-shadow border-radius-10 mb-4">
      <div class="card-body p-4">
        <div class="card-title d-flex justify-content-between align-items-center mb-3">
          <h3 class="fw-medium fs-18 mb-0">Project Overview</h3>
          <div class="dropdown action-opt">
            <button class="btn bg-transparent p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <vue-feather type="more-horizontal"></vue-feather>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" href="javascript:;" @click="toggleEdit">
                  <vue-feather type="edit"></vue-feather>
                  Edit
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="javascript:;">
                  <vue-feather type="eye"></vue-feather>
                  View
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="javascript:;">
                  <vue-feather type="trash"></vue-feather>
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Project</label>
                <select class="form-select form-control"
                        v-model="currentOrder.project.id"
                        :disabled="!editMode">
                  <option disabled value="">Select Project</option>
                  <option
                      v-for="project in userProjectsData"
                      :key="project.id"
                      :value="project.id"
                  >
                    {{ project.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Select Language</label>
                <select class="form-select form-control"
                        v-model="currentOrder.selected_language"
                        :disabled="!editMode">
                  <option disabled value="">Select language</option>
                  <option
                      v-for="lang in projectData.languages"
                      :key="lang.id"
                      :value="lang"
                  >
                    {{ lang.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <label class="label">Article Type</label>
              <select class="form-select form-control"
                      v-model="currentOrder.article_type.id"
                      :disabled="!editMode">
                <option disabled value="">Select Article</option>
                <option
                    v-for="article in projectData.article_type"
                    :key="article.id"
                    :value="article.id"
                >
                  {{ article.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Select Country</label>
                <select class="form-select form-control"
                        v-model="currentOrder.selected_country"
                        :disabled="!editMode">
                  <option disabled value="">Select country</option>
                  <option
                      v-for="country in projectData.countries"
                      :key="country.id"
                      :value="country"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <label class="label">Article Subtype</label>
              <select
                  class="form-select form-control"
                  v-model="articleSubTypeId"
                  :disabled="!editMode"
              >
                <option disabled value="">Select Article Subtype</option>
                <option v-for="subtype in subtypes"
                        :key="subtype.id"
                        :value="subtype.id">
                  {{ subtype.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Word count</label>
                <input v-model.number="currentOrder.word_count" type="number" min="0" class="form-control"
                       :disabled="!editMode"/>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Main keyword</label>
                <input type="text" class="form-control" v-model="currentOrder.main_keyword"
                       :disabled="!editMode"/>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Images count</label>
                <input v-model.number="currentOrder.images_count" type="number" min="0" class="form-control"
                       :disabled="!editMode"/>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Additional keywords</label>
                <input type="text" class="form-control"
                       v-model="currentOrder.additional_keyword" :disabled="!editMode"/>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Additional Requirements</label>
                <input type="text" class="form-control"
                       v-model="currentOrder.additional_requirements" :disabled="!editMode"/>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Deadline</label>
                <input v-model="currentOrder.dead_line" type="date" class="form-control" :disabled="!editMode"/>
              </div>
            </div>
            <!--Checkboxes-->
            <div class="col-lg-12">
              <div class="form-check c-form-check">
                <input class="form-check-input" type="checkbox" id="MetaDescription"
                       v-model="currentOrder.meta_description" :disabled="!editMode"/>
                <label class="form-check-label text-body fs-14" for="MetaDescription">Meta Description</label>
              </div>
              <div class="form-check c-form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault1" v-model="currentOrder.brand"
                       :disabled="!editMode"/>
                <label class="form-check-label text-body fs-14" for="flexCheckDefault1">Brand</label>
                <div class="col-lg-12" v-if="currentOrder.brand">
                  <div class="form-group mb-4">
                    <label class="label">Brand Name</label>
                    <input type="text" class="form-control"
                           v-model="currentOrder.brand_name" :disabled="!editMode"/>
                  </div>
                  <div class="form-group mb-4">
                    <label class="label">Brand Description</label>
                    <input type="text" class="form-control"
                           v-model="currentOrder.brand_description" :disabled="!editMode"/>
                  </div>
                  <div class="form-check c-form-check">
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault2"
                           v-model="currentOrder.include" :disabled="!editMode"/>
                    <label class="form-check-label text-body fs-14" for="flexCheckDefault2">Include</label>
                    <div class="col-lg-12" v-if="currentOrder.include">
                      <div class="form-check c-form-check">
                        <input class="form-check-input" type="checkbox" id="ToTitleCheckbox"
                               v-model="currentOrder.to_title" :disabled="!editMode"/>
                        <label class="form-check-label text-body fs-14" for="ToTitleCheckbox">To Title</label>
                      </div>
                      <div class="form-check c-form-check">
                        <input class="form-check-input" type="checkbox" id="DescriptionCheckbox"
                               v-model="currentOrder.to_description" :disabled="!editMode"/>
                        <label class="form-check-label text-body fs-14" for="DescriptionCheckbox">To Description</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Select Assigned to</label>
                <select class="form-select form-control"
                        v-model="currentOrder.assigned_to"
                        :disabled="!editMode">
                  <option disabled value="">Select Assigned</option>
                  <option :value="null">None</option>
                  <option
                      v-for="writer in projectData.writes"
                      :key="writer.id"
                      :value="writer">
                    {{ writer.user.username }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label class="label">Select Status</label>
                <select class="form-select form-control" v-model="currentOrder.status" :disabled="!editMode">
                  <option disabled value="">Select status</option>
                  <option
                      v-for="status in projectData.status"
                      :key="status.id"
                      :value="status"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="fs-14 fw-medium text-body mb-3" v-if="!editMode">
                <vue-feather type="command" style="width: 18px; stroke: #919aa3"></vue-feather>
                <span class="text-dark ms-2">Content:</span>
                <div class="col-lg-12" v-html="currentOrder.content"/>
              </div>
              <div class="fs-14 fw-medium text-body mb-3" v-if="editMode">
                <vue-feather type="command" style="width: 18px; stroke: #919aa3"></vue-feather>
                <span class="text-dark ms-2">Content:</span>
                <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
              </div>

            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-6">
              <button v-if="editMode" @click.prevent="saveChanges" class="btn btn-primary">Save</button>
              <button v-if="editMode" @click.prevent="cancelEdit" class="btn btn-secondary ms-2">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="container-fluid">
      <div class="inner-main-content">
        <div class="card border-0 box-shadow border-radius-10 mb-4">
          <div class="card-body px-4 py-5">
            <div class="text-center">
              <img
                  src="../../../assets/images/404.png"
                  class="mb-4"
                  alt="starter-img"
              />
              <h3 class="fs-30 fw-medium mb-1">Page Not Found</h3>
              <p class="text-body mb-4">
                But no worries! Our team is looking ever where while you wait
                safely.
              </p>
              <router-link
                  to="/"
                  class="default-btn text-decoration-none text-white"
              >
                Back To Home
              </router-link>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "ActiveProject",
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // Дополнительные настройки редактора
      },
      editMode: false,
    };
  },
  computed: {
    ...mapGetters(['currentOrder', 'projectData', 'username', 'userProjectsData']),
    subtypes() {
      const selectedType = this.projectData.article_type.find(type => type.id === this.currentOrder.article_type.id);
      return selectedType?.sub_type || [];
    },
    articleSubTypeId: {
      get() {
        return this.currentOrder.article_sub_type ? this.currentOrder.article_sub_type.id : null;
      },
      set(value) {
        if (this.currentOrder.article_sub_type) {
          this.currentOrder.article_sub_type.id = value;
        } else {
          this.currentOrder.article_sub_type = {id: value};
        }
      }
    },
    progressPercentage() {
      if (!this.currentOrder || !this.projectData.status.length) {
        return 0;
      }

      const orderStatusIndex = this.projectData.status.findIndex(status => status.name === this.currentOrder.status.name);

      if (orderStatusIndex === -1) {
        return 0;
      }

      return Math.round((orderStatusIndex) / (this.projectData.status.length - 1) * 100);
    }
  },
  methods: {
    ...mapActions(['updateOrder', 'fetchCurrentOrder', 'fetchOrdersData', 'fetchUserProjectData']),
    toggleEdit() {
      this.editorData = this.currentOrder.content;
      this.editMode = !this.editMode;
    },
    async saveChanges() {
      try {
        const orderData = {
          ...this.currentOrder,
          project: this.currentOrder.project.id,
          status: this.currentOrder.status.id,
          selected_country: this.currentOrder.selected_country.id,
          selected_language: this.currentOrder.selected_language.id,
          article_type: this.currentOrder.article_type.id,
          article_sub_type: this.articleSubTypeId,
          content: this.editorData,
        };

        if (this.currentOrder.assigned_to) {
          orderData.assigned_to = this.currentOrder.assigned_to.id;
        } else {
          orderData.assigned_to = null;
        }

        await this.updateOrder({id: this.currentOrder.id, orderData});
        await this.fetchOrdersData();
        // await this.fetchCurrentOrder(this.currentOrder.id);

        this.toggleEdit();
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    cancelEdit() {
      this.editMode = false;
    }
  },
  created() {
    this.fetchUserProjectData();
    if (!this.currentOrder) {
      const slug = this.$route.params.id;
      this.fetchCurrentOrder(slug);
    }
  },
};

</script>