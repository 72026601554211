<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <h4 class="text-dark fs-18 fw-medium mb-3">New Content</h4>
      <form @submit.prevent="submitForm">
        <div class="form-group mb-4">
          <label class="label">Category</label>
          <input type="text" class="form-control" placeholder="Enter Category" v-model="formData.category" required/>
        </div>
        <div class="form-group mb-4">
          <label class="label">Title</label>
          <input type="text" class="form-control" placeholder="Enter Title" v-model="formData.title" required/>
        </div>

        <div class="form-group mb-4">
          <label class="label">Description</label>
          <input type="text" class="form-control" placeholder="Enter Description" v-model="formData.description"/>
        </div>

        <div class="form-group mb-4">
          <label class="label">Content</label>
          <textarea class="form-control" rows="3" placeholder="Enter Content" v-model="formData.content"></textarea>
        </div>

        <div class="form-group mb-4">
          <label class="label">Content length (min 2000 - max 8000)</label>
          <input type="text" class="form-control" min="2000" max="8000" placeholder="Enter content length"
                 v-model="formData.content_len" required/>
        </div>

        <div class="form-group mb-0 d-flex">
          <button type="submit" class="default-btn">Create</button>
          <button type="button" class="default-btn border-btn ms-3" @click="redirectToHome">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { BACKEND_URL } from '@/constants';

export default {
  name: "FormLayout",
  setup() {
    const formData = reactive({
      category: "",
      title: "",
      description: "",
      content: "",
      content_len: ""
    });

    const router = useRouter();

    const submitForm = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const url = BACKEND_URL;

        const requestData = {
          category: formData.category,
          title: formData.title,
          content_len: formData.content_len
        };

        if (formData.description.trim() !== "") {
          requestData.description = formData.description;
        }

        if (formData.content.trim() !== "") {
          requestData.content = formData.content;
        }

        const response = await axios.post(`${url}/api/process_data/`, requestData);
        redirectToHome();

        console.log('Submitted data:', requestData);
        console.log(response.data);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };

    const redirectToHome = () => {
      router.push('/');
    };

    return {
      formData,
      submitForm,
      redirectToHome
    };
  }
};
</script>
