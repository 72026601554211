import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreLoader = _resolveComponent("PreLoader")!
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SettingsOption = _resolveComponent("SettingsOption")!
  const _component_SettingsModal = _resolveComponent("SettingsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_PreLoader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_MainHeader, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
      'main-content expanded js-container': _ctx.isLoggedIn,
      'collapsed': _ctx.isCollapsed,
    })
    }, [
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_MainSidebar, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_SettingsOption, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_SettingsModal)
    ], 2)
  ], 64))
}